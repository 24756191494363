<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        A hypothetical acid,
        <chemical-latex content="HA," />
        has a
        <stemble-latex :content="'$\\text{pK}_\\text{a}$'" />
        of
        <number-value :value="pKa" />
        . If a
        <stemble-latex :content="'$40.00\\text{ mL}$'" />
        solution containing
        <number-value :value="concHA" unit="\text{M}" />
        <chemical-latex content="HA" />
        is titrated with
        <number-value :value="concOH" unit="\text{M}" />
        <stemble-latex content="$\text{NaOH.}$" />
      </p>

      <p class="mb-2">Determine the volume of NaOH required to reach the equivalence point.</p>

      <calculation-input
        v-model="inputs.Veq"
        class="mb-5"
        prepend-text="$\text{V}_\text{eq}:$"
        append-text="$\text{mL}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">b) Determine the pH at the equivalence point.</p>

      <calculation-input
        v-model="inputs.pH1"
        class="mb-5"
        prepend-text="$\text{pH:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">c) Determine the pH after adding 40.00 mL of NaOH</p>

      <calculation-input
        v-model="inputs.pH2"
        prepend-text="$\text{pH:}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question308',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Veq: null,
        pH1: null,
        pH2: null,
      },
    };
  },
  computed: {
    concHA() {
      return this.taskState.getValueBySymbol('concHA').content;
    },
    concOH() {
      return this.taskState.getValueBySymbol('concOH').content;
    },
    pKa() {
      return this.taskState.getValueBySymbol('pKa').content;
    },
  },
};
</script>
